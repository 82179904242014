<template>
	<DashboardTemplate>
	<el-card class="box-card">
		<h2>Waiting For All Team’s Financial Product</h2>
		<div class="text-right">
	        <p><span class="font-weight-bold">Team submit:</span> <span :class="groupSend.length>=tableData.length ? 'color-success': ''">{{groupSend.length}}/{{tableData.length}}</span></p>
	    </div>
		<el-table
	    	border
			:data="tableData"
			style="width: 100%">
			<el-table-column
		      type="index"
		      width="70"
		      align="center"
		      label="ลำดับ">
		    </el-table-column>
	      <el-table-column
	        prop="name"
	        header-align="center"
	        label="Team">
	      </el-table-column>
	      <el-table-column
	      	align="center"
	      	header-align="center"
	        prop="status"
	        label="Status">
	         	<template slot-scope="scope">
		        	<div v-if="scope.row.status == 1" class="color-success">
		        		Submit
		        	</div>
		        </template>
	      </el-table-column>
	      <!-- <el-table-column
	     	header-align="center"
	        prop="timeSubmit"
	        label="Time">
		        <template slot-scope="scope">
		            {{scope.row.timeSubmit | timeTh}}
		        </template>
	      </el-table-column> -->
	    </el-table>
	    <el-row type="flex" justify="end" class="mg-t-3">
          <el-col :span="6">
          	<button  class="mg-y-5 w-100 btn-primary" type="submit" @click="confirmSend()" round>Next</button>
          </el-col>
      	</el-row>
  	</el-card>
  	<el-dialog :visible.sync="confirmModal" width="30%">
      <div v-if="groupModal.length > 0">
        <h3 class="text-center">รายชื่อทีมที่ไม่ซื้อผลิตภัณฑ์การเงิน</h3>
        <p v-for="(data,index) in groupModal" :key="index">{{index+1}}. {{data.name}}</p>
      </div>
      <div v-else class="text-center">
        <h3>ยืนยันการไปสู่ขั้นตอน Business Event</h3>
		<p>ผู้เล่นทุกคนจะไม่สามารถดำเนินการลงทุนใดๆ ได้อีก</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmModal = false">Cancel</el-button>
        <el-button type="primary" @click="submit()" :loading="loadingNext">Next</el-button>
      </span>
    </el-dialog>
	</DashboardTemplate>
</template>
<script>

import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'	
import DashboardTemplate from'@/template/DashboardTemplate'
	export default{
		components:{
			DashboardTemplate
		},
		data(){
			return{
				groupModal:[],
				confirmModal:false,
				groupSend:[],
				tableData: [],
				loadingNext:false
			}
		},
		computed:{
			room(){
				return this.$store.state.room
			}
		},
		mounted(){
			this.fetchData()
			this.onTeamSubmit()
		},
		methods:{
			onTeamSubmit(){
		      this.$socket.client.on('resWaitingFinancial',(res)=>{
		        if(res.success){
		        	this.groupSend = []
		          if(this.room._id == res.roomId){
		            this.tableData = res.obj;
		            res.obj.map(item => {
		              if (item.status == 1) {
		                this.groupSend.push(item);
		              }
		            })
		          } 
		        }
		      });
		    },
			fetchData(){
				this.tableData = []
				HTTP.get('/financial/'+this.room._id+'/'+this.room.currentRound).then(res=>{

					if(res.data.success){
						this.tableData = res.data.obj
						res.data.obj.map(item => {
			              if (item.status == 1) {
			                this.groupSend.push(item);
			              }
			            })
					}
				}).catch(e =>{
					console.log('/fianancial/ error', e)
				})
			},
			async confirmSend() {
		      this.groupModal = [];
		      await this.tableData.map(item => {
		        if (item.status != 1) {
		          this.groupModal.push(item);
		        }
		      });
		      this.confirmModal = true;
		    },
			async submit(){
				this.loadingNext = true
				this.room.current = 'Business Event Card'
				await this.$store.dispatch('updateCurrent',this.room)
				await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})

				setTimeout(() => {
					this.$router.push('/update-event-card').catch(err => {})
					this.loadingNext = false
				}, 500);
				
			}
		}
	}
</script>